import axios, { AxiosError, AxiosInstance } from "axios";

import { getToken, logout } from "@/functions";

import router, { route, ROUTE_NAME } from "@/router";

// eslint-disable-next-line
if (!ENV.API_URL) throw Error('No API root found')

// eslint-disable-next-line
axios.defaults.baseURL = ENV.API_URL

const api: AxiosInstance = axios.create()

api.interceptors.request.use(async function (config) {
    config.headers["Authorization"] = "Bearer " + getToken();
    return config;
})

api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, async function (error: AxiosError) {
    if (error.response?.status === 401) {
        if (route.value.meta?.auth) {
            await logout()
            if (route.value.meta?.admin) await router.replace({ name: 'LoginAdmin' })
            else await router.replace({ name: ROUTE_NAME.LOGIN })
        }
    }

    return Promise.reject(error);
});

export const noAuth = axios.create()

export default api
