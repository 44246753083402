<template>
    <div class="login-card bg-card">
        <q-img
            class="login-logo-company"
            src="egg-fill.svg"
            fit="contain"
        />

        <form>
            <div>
                <input-base
                    v-model="credentials.username"
                    class="login-label login-item"
                    label="User"
                    autocomplete="username"
                    @submit="$emit('auth', credentials)"
                />
            </div>

            <div>
                <input-base
                    v-model="credentials.password"
                    class="login-label login-item"
                    :type="INPUT_TYPE.PASSWORD"
                    data-cy="password"
                    label="Passwort"
                    autocomplete="current-password"
                    @submit="$emit('auth', credentials)"
                />
            </div>

            <div class="text-center">
                <div>{{ $t('label.noAccount') }}?</div>

                <div
                    class="fake-link"
                    @click="$router.push({ name: ROUTE_NAME.REGISTER })"
                >
                    {{ $t('label.registerNow') }}!
                </div>
            </div>
        </form>

        <div
            v-if="forgotPw"
            class="text-center mt-2"
        >
            <a
                class="force-a"
                data-cy="pw-reset"
                @click="$router.push({ name: 'PasswordResetRequest' })"
            >
                Passwort vergessen?
            </a>
        </div>

        <div class="center-x">
            <button-base
                data-cy="login-button"
                @click="$emit('auth', credentials)"
            >
                Anmelden
            </button-base>
        </div>
    </div>
</template>

<script setup lang="ts">
import { QImg } from "quasar";
import { ref } from "vue";

import ButtonBase from "@/components/button/ButtonBase.vue";
import { InputBase } from "@/components/input";

import { AuthCredentials, INPUT_TYPE } from "@/types";

import { ROUTE_NAME } from "@/router";

defineProps<{ forgotPw?: boolean; }>()

const credentials = ref<AuthCredentials>({ password: '', username: '' })

defineEmits<{
    (e: 'auth', value: AuthCredentials): void;
}>()
</script>

<style scoped>
.login-card {
    display: flex;
    flex-direction: column;
    flex: 0 1 500px;
    gap: 3rem;
    padding: 3rem;
    border-radius: 10px;
}

.login-logo-company {
    max-height: 200px;
}
</style>
