import { defineStore } from "pinia"

import { cleanedObject, request } from "@/functions";

import { FilterSettings, PaginationResponse, REQUEST_STATUS, StripeProduct } from "@/types";
import { APIBusiness, APIRequest, Business } from "@/types/object/class/db.ts";

type OrderID = string
type BusinessID = string

interface BusinessStore {
    businesses: PaginationResponse<APIBusiness>;
    businessesMap: Record<string, APIBusiness>;
    businessRequests: Record<OrderID, Record<BusinessID, APIRequest>>;
    filter: FilterSettings;
    newBusiness: Business;
    orderBusinesses: Record<OrderID, APIBusiness[]>;
    products: StripeProduct[];
    subscriptions: StripeProduct[];
}

const subscriptionHierarchy = ['Basis', 'Standard', 'Premium', 'Gold']

export const useBusinessStore = defineStore('business', {
    actions: {
        async createBusiness(body: Business) {
            return request<Business>({
                body,
                method: 'POST',
                route: '/businesses'
            })
        },
        async getBusiness(businessId: string, orderId?: string) {
            return request<APIBusiness>({
                method: 'GET',
                query: { orderId },
                route: `businesses/${businessId}`
            })
        },
        async getBusinesses(query?: FilterSettings) {
            const response = await request<PaginationResponse<APIBusiness>>({
                method: 'GET',
                query: {
                    ...cleanedObject((query ?? this.filter) ?? {})
                },
                route: '/businesses'
            })

            if (!response) return

            this.businesses = response
            response.data.forEach(business => {
                if (!business.id) return
                this.businessesMap[business.id] = business
            })

            return response
        },
        async getOrderBusinesses(orderId: string) {
            const response = await request<PaginationResponse<APIBusiness>>({
                method: 'GET',
                query: {
                    distanceInMeter: 1000000,
                    orderId
                },
                route: '/businesses'
            })

            if (!response) return

            this.orderBusinesses[orderId] = response.data
        },
        async getProducts() {
            const response = await request<{ products: StripeProduct[] }>({
                method: 'GET',
                route: '/payments/products'
            })

            if (!response) return

            this.products = response.products
            this.subscriptions = response.products.filter(product => product.metadata.isSubscription === 'true')
                .sort((a, b) => subscriptionHierarchy.indexOf(a.name) - subscriptionHierarchy.indexOf(b.name))

        },
        async updateBusiness() {

        }
    },
    getters: {
        byId(state): (businessId: string) => APIBusiness {
            return (businessId: string): APIBusiness => {
                return state.businessesMap[businessId] ?? new Business()
            }
        },
        matchedRequests(state): (orderId: string) => APIBusiness[] {
            return (orderId: string) => {
                return state.orderBusinesses[orderId]?.filter(business => business.additionalInformation.requestStatus == REQUEST_STATUS.ACCEPTED) ?? []
            }
        },
        orderBusinessRequest(state): (orderId: string) => APIBusiness[] {
            return (orderId: string) => {
                return state.orderBusinesses[orderId]?.filter(business => business.additionalInformation.requestStatus == REQUEST_STATUS.REQUESTED_BY_CONTRACTOR) ?? []
            }
        },
        orderBusinessRequested(state): (orderId: string) => APIBusiness[] {
            return (orderId: string) => {
                return state.orderBusinesses[orderId]?.filter(business => business.additionalInformation.requestStatus == REQUEST_STATUS.REQUESTED_BY_CUSTOMER) ?? []
            }
        }
    },
    state: (): BusinessStore => {
        return {
            businesses: {
                count: 0,
                data: [],
            },
            businessesMap: {},
            businessRequests: {},
            filter: new FilterSettings(),
            newBusiness: new Business(),
            orderBusinesses: {},
            products: [],
            subscriptions: []
        }
    }
})
