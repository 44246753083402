import { customizedErrorResponseHandler, handleAxiosError, isCustomizedErrorResponse, noAuth } from "@/functions";
import api from "@/functions/api";

import type { CustomizedResponse, RequestOption } from "@/types";

export const request = async <T = unknown>(reqParams: RequestOption): Promise<T | undefined> => {
    const { body, method, offset, query, responseType, route, showError } = reqParams

    const response = await api.request({
        data: body,
        method: method,
        params: query,
        responseType: responseType,
        url: route
    })
        .catch(handleAxiosError)

    if (isCustomizedErrorResponse(response)) {
        if (showError) await customizedErrorResponseHandler(response, { offset })
        return undefined
    }

    return response.data
}

export const requestBool = async (reqParams: RequestOption): Promise<boolean> => {
    const { body, method, offset, query, responseType, route, showError } = reqParams

    const response = await api.request({
        data: body,
        method: method,
        params: query,
        responseType: responseType,
        url: route
    })
        .catch(handleAxiosError)

    if (isCustomizedErrorResponse(response)) {
        if (showError) await customizedErrorResponseHandler(response, { offset })
        return false
    }

    return Boolean(response);
}

export const requestRaw = async (reqParams: RequestOption): Promise<CustomizedResponse> => {
    const { body, method, query, responseType, route } = reqParams

    return await api.request({
        data: body,
        method: method,
        params: query,
        responseType: responseType,
        url: route
    })
        .catch(handleAxiosError)
}

export const noAuthRequest = async <T = unknown>(reqParams: RequestOption): Promise<T | undefined> => {
    const { body, customErrorHandler, method, offset, query, responseType, route, showError } = reqParams

    const response = await noAuth.request({
        data: body,
        method: method,
        params: query,
        responseType: responseType,
        url: route
    })
        .catch(customErrorHandler ?? handleAxiosError)

    if (isCustomizedErrorResponse(response)) {
        if (showError) await customizedErrorResponseHandler(response, { offset })
        return undefined
    }

    return response.data
}

export const noAuthBool = async (reqParams: RequestOption): Promise<boolean> => {
    const { body, method, offset, query, responseType, route, showError } = reqParams

    const response = await noAuth.request({
        data: body,
        method: method,
        params: query,
        responseType: responseType,
        url: route
    })
        .catch(handleAxiosError)

    if (isCustomizedErrorResponse(response)) {
        if (showError) await customizedErrorResponseHandler(response, { offset })
        return false
    }

    return Boolean(response);
}

