const dialog = {
    checkLeave: 'Die Ansicht befindet sich noch im Bearbeitungsmodus. Eventuell können Daten verloren gehen. Möchtest du wirklich fortfahren?',
    checkOrderAccept: 'Willst du diesen Auftrag wirklich akzeptieren?',
    checkOrderDecline: 'Willst du diesen Auftrag wirklich ablehnen?',
    notVerifiedMail: 'Deine Email ist noch nicht verifiziert. Gewisse Funktionen werden nicht komplett freigeschaltet ' +
        'bis diese verifiziert ist.',
    requestBusinessFurtherSteps: 'Nach einer angenommenen Anfrage schalten wir Adresse und Kontaktdaten des Auftraggebers für dich frei.',
    requestBusinessHint: 'Bei Anfrage eines Betriebs entstehen Kosten für den Handwerker.{linebreak}' +
        'Bitte frage nur Betriebe an die dir wirklich zusagen.',
    requestSuccess: 'Anfrage erfolgreich',
    resendVerification: 'Verifizierungsmail erneut senden',
    resetPasswordCheck: 'Möchtest du wirklich dein Passwort zurücksetzen?',
}

export default dialog
