import { defineStore } from "pinia"

import { useBusinessStore } from "@/stores/business.ts";

import { cleanedObject, request, requestBool } from "@/functions";

import {
    CreateRequest,
    FilterSettings,
    ORDER_STATUS,
    ORDER_STATUS_TYPE,
    PaginationResponse,
    RequestSettings,
    USER_TYPE
} from "@/types";
import { APIOrder, APIRequest, Order } from "@/types/object/class/db.ts";

type OrderID = string;
type RequestID = string;

interface OrderStore {
    filter: FilterSettings;
    filterRequests: RequestSettings;
    newOrder: Order;
    orders: PaginationResponse<APIOrder>;
    requests: PaginationResponse<APIRequest>;
    requestsMap: Record<OrderID, Record<RequestID, APIRequest>>;
    userOrders: PaginationResponse<APIOrder>;
    userOrdersMap: Record<string, APIOrder>;
}

export const useOrderStore = defineStore('order', {
    actions: {
        async createOrder(order: {
            customer: string;
            pictures: string[]
        } & Omit<Order, 'customer' | 'pictures'>): Promise<APIOrder | undefined> {
            return request<APIOrder>({
                body: order,
                method: 'POST',
                route: '/orders'
            })
        },
        async createRequest(body: CreateRequest): Promise<APIRequest | undefined> {
            const response = await request<APIRequest>({
                body,
                method: 'POST',
                route: '/requests'
            })

            if (!response) return

            return response
        },
        async getAllOrders(filter?: Partial<FilterSettings>): Promise<PaginationResponse<Order> | undefined> {
            const response = await request<PaginationResponse<APIOrder>>({
                method: 'GET',
                query: {
                    ...cleanedObject((filter ?? useOrderStore.filter) ?? {})
                },
                route: '/orders'
            })

            if (!response) return

            this.orders = response
            response.data.forEach(order => {
                this.userOrdersMap[order?.id] = order
            })

            return response
        },
        async getRequests(orderId?: string): Promise<PaginationResponse<APIRequest> | undefined> {
            const response = await request<PaginationResponse<APIRequest>>({
                method: 'GET',
                query: {
                    orderId
                },
                route: '/requests'
            })

            if (!response) return

            this.requests = response

            if (!orderId) return response

            const businessStore = useBusinessStore()

            response.data.forEach((request: APIRequest) => {
                if (!orderId || !request.id) return
                if (!this.requestsMap[orderId]) this.requestsMap[orderId] = {}
                this.requestsMap[orderId][request.id] = request
                if (!businessStore.businessRequests[orderId]) businessStore.businessRequests[orderId] = {}
                businessStore.businessRequests[orderId][request.business] = request
            })
            return response
        },
        async getSingleOrder(orderId: string): Promise<APIOrder | undefined> {
            const response = await request<APIOrder>({
                method: 'GET',
                route: `/orders/${orderId}`
            })

            if (!response) return

            this.userOrdersMap[orderId] = response
            return response
        },
        async getUserOrders(): Promise<PaginationResponse<Order> | undefined> {
            const response = await request<PaginationResponse<APIOrder>>({
                method: 'GET',
                query: {
                    distanceInMeter: 1000000
                },
                route: '/orders'
            })

            if (!response) return

            this.userOrders = response
            response.data.forEach(order => {
                this.userOrdersMap[order.id] = order
            })

            return response
        },
        async setOrderStatus(orderId: string | undefined, status: ORDER_STATUS_TYPE): Promise<APIOrder | undefined> {
            return request<APIOrder>({
                body: { status },
                method: 'PUT',
                route: `/orders/${orderId}/status`
            })
        },
        async setRead(requestId: string | undefined) {
            if (!requestId) return

            return requestBool({
                method: 'PUT',
                route: `/requests/${requestId}/readStatus`,
            })
        },
        async setRequestStatus(requestId: string | undefined, status: 'ACCEPTED' | 'DECLINED') {
            if (!requestId) return

            return requestBool({
                body: {
                    status
                },
                method: 'PUT',
                route: `/requests/${requestId}/status`
            })
        },
        async updateOrder(order: {
            customer: string;
            pictures: string[]
        } & Omit<Order, 'customer' | 'pictures'>): Promise<APIOrder | undefined> {
            return request<APIOrder>({
                body: order,
                method: 'PATCH',
                route: `/orders/${order?.id}`
            })
        }
    },
    getters: {
        orderRequests(state): (orderId: string | undefined) => APIRequest[] {
            return (orderId: string | undefined): APIRequest[] => {
                if (!orderId) return []

                return state.requests.data.filter((request: APIRequest) => {
                    if (typeof request.order === 'string') return request.order === orderId
                    return request.order?.id === orderId
                }) ?? []
            }
        },
        requestedOrders(state): APIRequest[] {
            return state.requests.data.map(request => {
                if (!request.order || typeof request.order !== 'string' || request.requestedBy !== USER_TYPE.CONTRACTOR) return
                return { ...request, order: state.userOrdersMap[request.order] }
            }).filter(request => !!request && !!request.order && request.order?.status === ORDER_STATUS.ACTIVE)
        },
        requestedOrdersCustomer(state): APIRequest[] {
            return state.requests.data.map(request => {
                if (!request.order || typeof request.order !== 'string' || request.requestedBy !== USER_TYPE.CUSTOMER) return
                return { ...request, order: state.userOrdersMap[request.order] }
            }).filter(request => !!request && !!request.order && request.order?.status === ORDER_STATUS.ACTIVE)
        }
    },
    state: (): OrderStore => {
        return {
            filter: new FilterSettings(),
            filterRequests: new RequestSettings(),
            newOrder: new Order(),
            orders: {
                count: 0,
                data: [],
            },
            requests: {
                count: 0,
                data: []
            },
            requestsMap: {},
            userOrders: {
                count: 0,
                data: [],
            },
            userOrdersMap: {}
        }
    }
})
