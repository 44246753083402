import { computed } from "vue";

import { AvailableLayouts, layouts } from "@/layouts";

import { route } from "@/router";

export default function useRouteParams() {
    const id = computed<string>(() => String(route.value.params.id))

    return {
        id,
    }
}

const isLayoutKey = (key: unknown): key is AvailableLayouts => {
    if (!key || typeof key !== 'string') return false
    return Object.keys(layouts).includes(key);
}

export function useRouteMeta() {
    const layout = computed<typeof layouts[AvailableLayouts]>(() => {
        const layoutName = route.value.meta?.layout
        if (!isLayoutKey(layoutName)) return layouts['LayoutBase']
        return layouts[layoutName] ?? layouts['LayoutBase']
    })

    return {
        layout
    }
}
