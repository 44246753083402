<template>
    <q-layout view="hHh lpR fFf">
        <custom-sidebar>
            <q-img
                src="/egg-fill.svg"
                class="mt-2 mb-5"
                ratio="1"
                style="height: var(--logo-size); width: var(--logo-size);"
            />

            <q-list>
                <q-item-label
                    header
                    class="text-textm"
                >
                    {{ $t('label.yourOverview') }}
                </q-item-label>

                <q-item
                    v-for="routeEl of businessRoutes"
                    :key="routeEl.routeName"
                    v-ripple
                    clickable
                    :active="route.name === routeEl.routeName || routeEl.activeRoutes?.includes(route.name)"
                    active-class="fw-b"
                    class="sidebar-option"
                    @click="router.push({ name: routeEl.routeName })"
                >
                    <q-item-section side>
                        <q-icon
                            :name="routeEl.icon"
                            :color="route.name === routeEl.routeName || routeEl.activeRoutes?.includes(route.name) ? 'primary' : 'text'"
                        />
                    </q-item-section>

                    <q-item-section>
                        {{ $t(`label.route.${routeEl.routeName}`) }}
                    </q-item-section>

                    <q-item-section
                        v-if="routeEl.marked?.()"
                        side
                    >
                        <q-icon
                            name="circle"
                            class="text-negative"
                        />
                    </q-item-section>
                </q-item>

                <q-item-label
                    header
                    class="pt-5 text-textm"
                >
                    EGOON
                </q-item-label>

                <q-item
                    v-for="supportRoute of supportRoutes.filter(route => !route.exclusiveFor || (route.exclusiveFor && route.exclusiveFor.includes(userStore.currentUser.userRole)))"
                    :key="supportRoute.routeName"
                    v-ripple
                    clickable
                    :active="route.name === supportRoute.routeName"
                    class="sidebar-option"
                    @click="supportRoute.clickFn ? supportRoute.clickFn() : router.push({ name: supportRoute.routeName })"
                >
                    <q-item-section side>
                        <q-icon
                            :name="supportRoute.icon"
                            color="text"
                        />
                    </q-item-section>

                    <q-item-section>
                        {{ $t(`label.route.${supportRoute.routeName}`) }}
                    </q-item-section>
                </q-item>

                <q-item class="justify-center">
                    <button-base
                        outline
                        text-color="primary"
                        icon="logout"
                        :label="$t('button.logout')"
                        @click="logout"
                    />
                </q-item>
            </q-list>
        </custom-sidebar>

        <q-page-container>
            <q-page
                padding
                style="padding-top: calc(var(--logo-size, 0px) + 3.75em); min-width: 860px;"
            >
                <slot />
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script setup lang="ts">
import { QIcon, QImg, QItem, QItemLabel, QItemSection, QLayout, QList, QPage, QPageContainer } from "quasar";
import { computed } from "vue";

import { ButtonBase, CustomSidebar } from "@/components";

import { useOrderStore } from "@/stores/order.ts";
import { useUserStore } from "@/stores/user.ts";

import { logout } from "@/functions";

import { FunctionType, USER_TYPE, USER_TYPE_TYPE } from "@/types";

import router, { route, ROUTE_NAME, ROUTE_NAME_TYPE } from "@/router";

defineProps<{ title?: string }>()

const userStore = useUserStore()
const orderStore = useOrderStore()

type RouteObject = {
    activeRoutes?: ROUTE_NAME_TYPE[];
    clickFn?: FunctionType
    icon: string;
    marked?: FunctionType<boolean>;
    routeName: ROUTE_NAME_TYPE;
}

const businessRoutes: RouteObject[] = computed(() => [
    {
        activeRoutes: [ROUTE_NAME.DASHBOARD_CONTRACTOR, ROUTE_NAME.DASHBOARD_CUSTOMER],
        icon: 'grid_view',
        routeName: ROUTE_NAME.HOME
    },
    {
        icon: 'format_list_bulleted',
        marked: (): boolean => {
            if (userStore.currentUser?.userRole === USER_TYPE.CUSTOMER) return !!orderStore.userOrders.data.find(order => order.additionalInformation?.unreadRequests > 0)
            return !!orderStore.orders.data.find(order => order.additionalInformation?.unreadRequests > 0)
        },
        // marked: true, //TODO: Make this conditional on new status updates
        routeName: userStore.currentUser?.userRole === USER_TYPE.CUSTOMER ? ROUTE_NAME.ORDERS_AND_REQUESTS : ROUTE_NAME.ORDERS_AND_REQUESTS_CONTRACTOR
    },
    {
        icon: userStore.currentUser?.userRole === USER_TYPE.CUSTOMER ? 'person' : 'handyman',
        routeName: userStore.currentUser?.userRole === USER_TYPE.CUSTOMER ? ROUTE_NAME.EDIT_PROFILE : ROUTE_NAME.EDIT_BUSINESS
    }
])

const supportRoutes: ({ exclusiveFor?: USER_TYPE_TYPE[] } & RouteObject)[] = [
    {
        clickFn: async () => {
            const response = await userStore.getPortalSession()

            if (!response) return

            window.open(response, '_blank')
        },
        exclusiveFor: [USER_TYPE.CONTRACTOR],
        icon: 'payments',
        routeName: ROUTE_NAME.MANAGE_SUBSCRIPTION
    },
    {
        icon: 'help_outline',
        routeName: ROUTE_NAME.SUPPORT
    }
]
</script>

<style>
:root {
    --logo-size: 50px;
}

.sidebar-option {
    font-size: 16px;
}
</style>
