export enum DATE_FORMAT {
    DE = 'dd.MM.yyyy',
    DE_LONG = 'dd.MMMM.yyyy',
    DE_SHORT = 'dd.MM.yy',
    EN = 'dd/MM/yyyy',
    EN_LONG = 'dd MMMM yyyy',
    EN_SHORT = 'dd/MM/yy',
    ISO_DATE = 'yyyy-MM-dd',
    US = 'M/d/yyyy',
    US_LONG = 'MMMM d, yyyy',
    US_SHORT = 'M/d/yy'
}

export enum TIME_FORMAT {
    DE = 'HH:mm',
    DE_LONG = 'HH:mm:ss',
    US = 'H:MM a',
    US_LONG = 'H:MM:ss a'
}

export enum DATE_TIME_FORMAT {
    DE = 'dd.MM.yyyy HH:mm'
}

export type ISO = 'iso'
export type NOW = 'now'

export type LUXON_FORMAT = DATE_FORMAT | DATE_TIME_FORMAT | ISO | TIME_FORMAT
