import LayoutBase from './LayoutBase.vue';
import LayoutEmpty from "./LayoutEmpty.vue";
import LayoutLogin from "./LayoutLogin.vue";

export const layouts = {
    LayoutBase,
    LayoutEmpty,
    LayoutLogin,
}

export type AvailableLayouts = keyof typeof layouts

export {
    LayoutBase,
    LayoutEmpty,
    LayoutLogin,
}

