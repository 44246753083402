import { defineStore } from "pinia";

import { request } from "@/functions";

import type { PaginationResponse, UpdateTableOption } from "@/types";

export interface QuasarPagination {
    descending?: boolean;
    page: number;
    rowsNumber: number;
    rowsPerPage: number;
    sortBy?: string;
}

export type PiniaPaginationInfo = { filter?: string; } & QuasarPagination

export interface PaginationData {
    list: any[];
    pagination: PiniaPaginationInfo
}

export interface PaginationStore {
    data: {
        [key: string]: PaginationData
    }
}

export const usePaginationStore = defineStore('pagination', {
    actions: {
        async refreshList(listRoute: string) {
            const tableInfo = this.data[listRoute]
            if (!tableInfo) return

            await this.setList({
                pagination: tableInfo.pagination,
                routePath: listRoute
            })
        },
        async setList(params: UpdateTableOption) {
            const {
                convertFunction,
                optQueries,
                pagination,
                routePath
            } = params

            if (!this.data[routePath]) this.data[routePath] = {
                list: [],
                pagination: { page: 1, rowsNumber: 0, rowsPerPage: 0 }
            }

            Object.assign(this.data[routePath].pagination, pagination)

            const response = await request<PaginationResponse>({
                method: 'get',
                query: {
                    filterValues: [pagination.filter],
                    limit: pagination.rowsPerPage,
                    skip: (pagination.page - 1) * pagination.rowsPerPage,
                    ...optQueries
                },
                route: routePath
            });

            if (!response) return;

            const list = response.data;
            this.data[routePath].pagination.rowsNumber = response.count

            if (convertFunction) {
                const convertedList: any[] = convertFunction(list)
                if (convertedList) this.data[routePath].list = convertedList
            } else this.data[routePath].list = list
        }
    },
    getters: {
        getPaginationList(state): (routePath: string) => any[] {
            return (routePath: string): any[] => {
                const routePaginationInfo = state.data[routePath]
                if (!routePaginationInfo) return []
                return routePaginationInfo.list
            }
        },
        getPaginationObject(state): (routePath: string) => PiniaPaginationInfo {
            return (routePath: string): PiniaPaginationInfo => {
                const routePaginationInfo = state.data[routePath]
                if (!routePaginationInfo) return {
                    page: 1,
                    rowsNumber: 0,
                    rowsPerPage: 10
                }
                return routePaginationInfo.pagination
            }
        },
    },
    state: (): PaginationStore => {
        return {
            data: {}
        }
    }
})
