import { DateTime, DurationObjectUnits } from "luxon";

import { DATE_FORMAT, ISODate, type LUXON_FORMAT } from "@/types";

export const convertFormat = (date: string | undefined, from: LUXON_FORMAT, to: LUXON_FORMAT): ISODate | string => {
    let formattedDate: DateTime | undefined
    if (!date || !from || !to) return ''
    switch (from) {
        case "iso":
            formattedDate = DateTime.fromISO(date)
            if (!formattedDate || !formattedDate.isValid) return ''
            break;
        default:
            formattedDate = DateTime.fromFormat(date, from)
            if (!formattedDate || !formattedDate.isValid) return ''
            break;
    }

    switch (to) {
        case "iso":
            return formattedDate.toISO() ?? ''
        default:
            return formattedDate.toFormat(to)
    }
}

const isISODate = (date: string | undefined): date is ISODate => {
    if (!date) return false

    return DateTime.fromISO(date).isValid
}

export const convertToISO = (date: string | undefined): ISODate => {
    if (isISODate(date)) return DateTime.fromISO(date).toISO()

    return convertFormat(date, DATE_FORMAT.ISO_DATE, 'iso')
}

export const ensureDateTime = (value: DateTime | string, format: LUXON_FORMAT = 'iso'): DateTime => {
    if (typeof value === 'string') {
        if (format === 'iso') return DateTime.fromISO(value)
        return DateTime.fromFormat(value, format)
    }
    return value
}

/**
 * Returns an object with the difference between two Dates.
 * If no second Date was provided the difference will be calculated between the first Date and now.
 *
 * If a difference value is positive the first Date is after the second Date.
 * @param firstDate
 * @param secondDate
 * @param options
 * @return {DurationObjectUnits}
 */
export const diffDates = (
    firstDate: DateTime,
    secondDate: DateTime = DateTime.now(),
    options: Array<'days' | 'hours' | 'minutes' | 'months'> = ['days', 'hours', 'minutes']
): DurationObjectUnits => {
    if (!firstDate.isValid || !secondDate.isValid) return { days: 0, hours: 0, minutes: 0, months: 0 }
    return firstDate.diff(secondDate, options).toObject()
}

export const sortDateLuxon = (a: DateTime | string, b: DateTime | string, format: LUXON_FORMAT = 'iso'): number => {
    const dateA = ensureDateTime(a, format)
    if (!dateA.isValid) return -1
    const dateB = ensureDateTime(b, format)
    if (!dateB.isValid) return 1
    return dateA.diff(dateB, ['seconds']).seconds
}

export const luxonNow = (format: LUXON_FORMAT = DATE_FORMAT.DE): string => {
    if (format === 'iso') return DateTime.now().toISO() ?? ''
    return DateTime.now().toFormat(format)
}
