<template>
    <div class="login-view">
        <auth-card
            :forgot-pw="forgotPw"
            @auth="sendLoginData"
        />
    </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import AuthCard from "@/components/card/AuthCard.vue";

import { useUserStore } from "@/stores/user.ts";

import { popToast } from "@/functions";

import { AuthCredentials } from "@/types";
import { QUASAR_COLOR_CONST } from "@/types/enum";

import router, { ROUTE_NAME } from "@/router";

const { t } = useI18n()

defineProps<{ forgotPw?: boolean; }>()

const sendLoginData = async (credentials: AuthCredentials) => {
    if (!credentials.username) return popToast({
        color: QUASAR_COLOR_CONST.negative,
        group: 'user-empty',
        message: t('error.empty', { target: t('target.username') })
    })

    if (!credentials.password) return popToast({
        color: QUASAR_COLOR_CONST.negative,
        group: 'password-empty',
        message: t('error.empty', { target: t('input.password') })
    })

    const result = await useUserStore().login(credentials)

    if (!result.token) return

    console.log('login')

    await router.push({ name: ROUTE_NAME.HOME })
}
</script>

<style scoped>
.login-view {
    display: flex;
    height: 100vh;
    flex: 1 1 100%;
    justify-content: center;
    align-items: center;
}
</style>
